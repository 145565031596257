<template>
  <div :class="['left-side-bar', {'opened' : isOpen}]">
    <div class="left-side-bar__body">
      <div class="left-side-bar__user-information-body">
        <div class="left-side-bar__user-information-body-content">
          <div class="left-side-bar__user-information-body-content__avatar">
            <svg fill="none" height="36" viewBox="0 0 36 36" width="36" xmlns="http://www.w3.org/2000/svg">
              <circle cx="18" cy="18" r="16.875" stroke="#333F4F" stroke-width="2.25"/>
              <path
                  d="M18 23C21.866 23 25 19.6421 25 15.5C25 11.3579 21.866 8 18 8C14.134 8 11 11.3579 11 15.5C11 19.6421 14.134 23 18 23Z"
                  stroke="#333F4F" stroke-miterlimit="10" stroke-width="2.25"/>
              <path
                  d="M7 28.9998C8.11524 27.1755 9.71884 25.6607 11.6497 24.6075C13.5806 23.5544 15.7707 23 18.0001 23C20.2294 23 22.4196 23.5545 24.3504 24.6077C26.2812 25.6609 27.8848 27.1757 29 29"
                  stroke="#333F4F" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.25"/>
            </svg>
          </div>
          <div class="left-side-bar__user-information-body-content__info">
            <span class="left-side-bar__user-information-body-content__info-name">{{ userName }}</span>
            <span class="left-side-bar__user-information-body-content__info-position">{{ userPosition }}</span>
          </div>
        </div>
        <div v-if="isOpen" class="left-side-bar__user-information-body__close">
          <svg fill="none" height="36" viewBox="0 0 36 36" width="36" xmlns="http://www.w3.org/2000/svg"
               @click="$emit('closeMenu')">
            <path d="M28.125 7.875L7.875 28.125" stroke="#333F4F" stroke-linecap="round" stroke-linejoin="round"
                  stroke-width="2"/>
            <path d="M28.125 28.125L7.875 7.875" stroke="#333F4F" stroke-linecap="round" stroke-linejoin="round"
                  stroke-width="2"/>
          </svg>
        </div>
      </div>
      <div class="left-side-bar__menu-list">
        <ul class="left-side-bar__menu-list-items" @click="$emit('closeMenu')">
          <template v-for="item in menu">
            <router-link
                v-if="canISowMenu(item.allowed)"
                :key="item.id"
                v-slot="{ href, route, navigate, isActive, isExactActive }"
                :to="item.link"
                custom
            >
              <li :class="[isActive && 'router-link-active', isExactActive && 'router-link-exact-active']"
                  @click="navigate"
              >
                {{ item.title }}
              </li>
            </router-link>
            <ul :key="`${item.id}-child`" :class="['left-side-bar__menu-list-items__submenu-items']">
              <template v-for="child in item.children">
                <router-link
                    v-if="item.allowed && child.isShow"
                    :key="child.id"
                    v-slot="{ route, navigate, isActive, isExactActive }"
                    :to="child.link"
                    custom
                >
                  <li v-scroll-to="route.hash"
                      :class="[isActive && 'router-link-active', isExactActive && 'router-link-exact-active']" @click="navigate">
                    <v-icon :class="[{'isBell' : child.isBell}]" :hovering="false">{{ child.icon }}</v-icon>
                    <span style="padding-left: 22px">{{ child.title }}</span>
                  </li>
                </router-link>
              </template>
            </ul>
          </template>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import VIcon from "../icons/VIcon";

export default {
  name: "LeftSideBar",
  components: {VIcon},
  props: {
    userIsChecked: {
      type: Boolean,
      default: false
    },
    isOpen: {
      type: Boolean,
      default: false
    },
    userName: {
      type: String,
      default: "User"
    },
    userPosition: {
      type: String,
      default: "Инвестор"
    },
    newDocs: {
      type: Boolean,
      default: false
    },
    newPIF: {
      type: Boolean,
      default: false
    },
    newInfo: {
      type: Boolean,
      default: false
    },
    isRedemption: {
      type: Boolean,
      default: false
    },
    isChangesVisible: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    menu() {
      return [
        {title: "Все фонды", link: "/cabinet/funds", id: "funds-menu", allowed: 3},
        {
          title: "Инвестиционный портфель", link: "/cabinet/investment", id: "investment-menu", allowed: 3, children: [
            {link: "/cabinet/investment/owned-shares", title: "Паи фондов во владении", icon: "table", isShow: true},
            {link: "/cabinet/investment/purchase", title: "Заявки на покупку", icon: "briefcase-1", isShow: true},
            {link: "/cabinet/investment/redemption", title: "Заявки на погашение", icon: "briefcase", isShow: this.isRedemption},
          ]
        },
        {
          title: "Профиль", link: "/cabinet/profile", id: "profile-menu", allowed: 2, children: [
            {
              link: "/cabinet/profile#account",
              title: "Учетная запись",
              icon: "user",
              id: "profile-account-menu",
              isShow: true
            },
            {
              link: "/cabinet/profile#personal_data",
              title: "Персональные данные",
              icon: "ID",
              id: "profile-personaldata-menu",
              isShow: true
            },
            {
              link: "/cabinet/profile#bank_details",
              title: "Банковские реквизиты",
              icon: "bank-rec",
              id: "profile-bankdetales-menu",
              isShow: true
            },
          ]
        },
        {
          title: "Сообщения", link: "/cabinet/messages", id: "messages-menu", allowed: 3, children: [
            {
              link: "/cabinet/messages/documents",
              title: "Документы",
              icon: "bell",
              id: "messages-documents-menu",
              isShow: true,
              isBell: this.newDocs
            },
            {
              link: "/cabinet/messages/pif",
              title: "ПИФы",
              icon: "bell",
              id: "messages-pif-menu",
              isShow: true,
              isBell: this.newPIF
            },
            {
              link: "/cabinet/messages/info",
              title: "Общая информация",
              icon: "bell",
              id: "messages-info-menu",
              isShow: true,
              isBell: this.newInfo
            },
          ]
        },
        {
          title: "Документы", link: "/cabinet/documents", id: "documents-menu", allowed: 3, children: [
            {
              link: "/cabinet/documents/FATCA",
              title: "Анкеты FATCA",
              icon: "note",
              id: "documents-FATCA-menu",
              isShow: true
            },
            {
              link: "/cabinet/documents/POD-FT",
              title: "Анкеты ФЛ",
              icon: "note",
              id: "documents-POD-FT-menu",
              isShow: true
            },
            {
              link: "/cabinet/documents/KI",
              title: "Квалификация",
              icon: "note-pencil",
              id: "documents-KI-menu",
              isShow: true
            },
            {
              link: "/cabinet/documents/personal-accounts",
              title: "Лицевые счета",
              icon: "receipt",
              id: "documents-personal-accounts-menu",
              isShow: true
            },
            {
              link: "/cabinet/documents/agreements",
              title: "Соглашения",
              icon: "clipboard-text",
              id: "documents-agreements-menu",
              isShow: true
            },
            {
              link: "/cabinet/documents/personal-data-change",
              title: "Изменение персональных данных",
              icon: "user-circle-gear",
              id: "documents-personal-data-change-menu",
              isShow: this.isChangesVisible
            },
          ]
        },
        {
          title: "Информация об УК", link: "/cabinet/about", id: "about-menu", allowed: 3, children: [
            {
              link: "/cabinet/about/contacts",
              title: "Контакты",
              icon: "paper-plane-tilt",
              id: "about-contacts-menu",
              isShow: true
            },
            {
              link: "/cabinet/about/legal-information",
              title: "Юридическая информация",
              icon: "clipboard",
              id: "about-legal-information-menu",
              isShow: true
            },
            {link: "/cabinet/about/us", title: "О нас", icon: "notebook", id: "about-us-menu", isShow: true},
          ]
        },
      ]
    }
  },
  data() {
    return {
      // menu: [
      //     {title: "Инвестиционный портфель", link:"/cabinet/investment", id:"investment-menu", allowed: 3, children:[
      //             {link: "/cabinet/investment/funds", title: "Все фонды", icon: "chart-pie-slice", isShow: true},
      //             {link: "/cabinet/investment/owned-shares", title: "Паи фондов во владении", icon: "table", isShow: true},
      //             {link: "/cabinet/investment/purchase", title: "Заявки на покупку", icon: "briefcase-1", isShow: true},
      //             {link: "/cabinet/investment/redemption", title: "Заявки на погашение", icon: "briefcase", isShow: this.isSellingList},
      //         ]},
      //     {title: "Профиль", link:"/cabinet/profile", id:"profile-menu", allowed: 2, children:[
      //             {link: "/cabinet/profile#account", title: "Учетная запись", icon: "user", id: "profile-account-menu", isShow: true},
      //             {link: "/cabinet/profile#personal_data", title: "Персональные данные", icon: "ID", id: "profile-personaldata-menu", isShow: true},
      //             {link: "/cabinet/profile#bank_details", title: "Банковские реквизиты", icon: "bank-rec", id: "profile-bankdetales-menu", isShow: true},
      //         ]},
      //     {title: "Сообщения", link:"/cabinet/messages", id:"messages-menu", allowed: 3, children:[
      //             {link: "/cabinet/messages/documents", title: "Документы", icon: "bell", id: "messages-documents-menu", isShow: true},
      //             {link: "/cabinet/messages/pif", title: "Персональные данные", icon: "bell", id: "messages-pif-menu", isShow: true},
      //             {link: "/cabinet/messages/info", title: "Банковские реквизиты", icon: "bell", id: "messages-info-menu", isShow: true},
      //         ]},
      //     {title: "Документы", link:"/cabinet/documents", id:"documents-menu", allowed: 3, children:[
      //             {link: "/cabinet/documents/FATCA", title: "Анкеты FATCA", icon: "note", id: "documents-FATCA-menu", isShow: true},
      //             {link: "/cabinet/documents/POD-FT", title: "Анкеты ПОД/ФТ", icon: "note", id: "documents-POD-FT-menu", isShow: true},
      //             {link: "/cabinet/documents/KI", title: "Признание КИ", icon: "note-pencil", id: "documents-KI-menu", isShow: true},
      //             {link: "/cabinet/documents/personal-accounts", title: "Лицевые счета", icon: "receipt", id: "documents-personal-accounts-menu", isShow: true},
      //             {link: "/cabinet/documents/agreements", title: "Соглашения", icon: "clipboard-text", id: "documents-agreements-menu", isShow: true},
      //             {link: "/cabinet/documents/personal-data-change", title: "Изменение персональных данных", icon: "user-circle-gear", id: "documents-personal-data-change-menu", isShow: true},
      //         ]},
      //     {title: "Информация об УК", link:"/cabinet/about", id:"about-menu", allowed: 3, children:[
      //             {link: "/cabinet/about/contacts", title: "Контакты", icon: "paper-plane-tilt", id: "about-contacts-menu", isShow: true},
      //             {link: "/cabinet/about/legal-information", title: "Юридическая информация", icon: "clipboard", id: "about-legal-information-menu", isShow: true},
      //             {link: "/cabinet/about/us", title: "О нас", icon: "notebook", id: "about-us-menu", isShow: true},
      //         ]},
      // ]
    }
  },
  methods: {
    canISowMenu(isAllowNumber) {
      if (this.userIsChecked) {
        return isAllowNumber >= 2
      } else {
        return isAllowNumber === 2
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.left-side-bar {
  width: 0;
  position: absolute;
  transition: width 300ms, flex 300ms;
  overflow-x: hidden;
  z-index: 101;
  /*top: 0;*/
  /*bottom: 0;*/
  background-color: #F6F7F9;
  white-space: nowrap;
  display: flex;
  min-height: 100vh;

  &:after {
    content: "";
    background-color: $RWM-grey-super-light;
    opacity: 0.15;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;
  }

  @media screen and (min-width: 961px) {
    overflow: hidden;
    width: 355px;
    max-width: 355px;
    flex: 1 0 355px;
    position: relative;
    z-index: 1;
    white-space: normal;
    background-color: transparent;
  }

  &.opened {
    width: 100%;
    white-space: normal;
  }

  &__body {
    height: 100%;
    width: 100%;
    overflow-y: auto;
    white-space: inherit;
  }

  &__user-information-body {
    width: 100%;
    padding: 34px 0;
    text-align: left;
    display: flex;
    position: sticky;
    top: 0;
    z-index: 5;
    background-color: #F6F7F9;
    white-space: inherit;

    &-content {
      padding-left: 40px;
      display: flex;
      align-items: center;
      flex: 1 1 auto;
      white-space: inherit;

      &__info {
        padding-left: 12px;
        display: flex;
        flex-direction: column;
        white-space: inherit;

        &-name {
          color: $RWM-dark;
          font-weight: 500;
          font-size: 17px;
          line-height: 23px;
          white-space: nowrap;
          white-space: inherit;
        }

        &-position {
          color: $RWM-grey;
          font-weight: normal;
          font-size: 13px;
          line-height: 18px;
          white-space: nowrap;
        }
      }
    }

    &__close {
      padding-right: 40px;
    }
  }

  &__menu-list {
    display: flex;
    padding-top: 5px;

    &-items {
      margin: 0;
      padding: 0 10px 0 40px;
      list-style-type: none;
      width: 100%;
      text-align: left;

      & li {
        font-weight: bold;
        font-size: 17px;
        line-height: 23px;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: $RWM-dark;
        padding: 20px 0 8px 0;
        transition: color 300ms;
        cursor: pointer;
        margin-bottom: 16px;

        &:hover {
          color: $RWM-red;
        }

        &.router-link-active,
        &.router-link-exact-active {
          color: $RWM-red;
        }

        &.router-link-active + ul {
          max-height: 1000px;
        }
      }

      &__submenu-items {
        list-style-type: none;
        padding-left: 0;
        overflow: hidden;
        max-height: 0;
        transition: max-height 300ms;

        & li {
          font-weight: normal;
          font-size: 19px;
          line-height: 26px;
          text-transform: none;
          padding: 8px 0;
          display: flex;
          align-items: center;
        }
      }
    }
  }
}
</style>
