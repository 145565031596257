<template>
  <cabinet-wrapper
    :user-name="GetUserName"
    :user-position="GetUserPosition"
    :user-is-checked="isAPROVED"
    :messages-count="MESSAGES_COUNT"
    :new-docs="NEW_DOCS"
    :new-info="NEW_INFO"
    :new-p-i-f="NEW_PIF"
    :is-redemption="IS_REDEMPTION"
    :is-changes-visible="IS_VISIBE_CHANGES"
    :contacts="getCONTACTS"
    @logout="logout()"
    @sendMail="$emit('sendMail')"
    @showMessages="showMessages()"
    @sendMessage="sendMessage()"
  >
    <router-view></router-view>
  </cabinet-wrapper>
</template>

<script>
import CabinetWrapper from "../components/modules/CabinetWrapper";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "ClientCabinet",
  components: { CabinetWrapper },
  computed: {
    ...mapGetters("AuthModule", [
      "GetUserName",
      "GetUserPosition",
      "isAPROVED",
      "isAUTHORIZED",
    ]),
    ...mapGetters("Messages", [
      "MESSAGES_COUNT",
      "NEW_DOCS",
      "NEW_PIF",
      "NEW_INFO",
      "MESSAGES_ROUTE",
    ]),
    ...mapGetters("Sockets", ["IS_CONNECTED", "IS_REDEMPTION", "IS_VISIBE_CHANGES"]),
    ...mapGetters("AboutModule", ["getCONTACTS"]),
  },
  data() {
    return {};
  },
  methods: {
    ...mapActions("AboutModule", ["GET_ABUT_INFORMATION"]),
    ...mapActions("AuthModule", ["LOGOUT", "CLEAR_LOGIN"]),
    ...mapActions("Sockets", [
      "SOCKET_CONNECT",
      "SOCKET_visibleSellShares",
      "SOCKET_visibleChanges",
      "SOCKET_DISCONNECT",
      "SOCKET_isVisibleSellShares",
      "SOCKET_isVisibleChanges",
      "SOCKET_GET_OUT",
      "SOCKET_NEW_MESSAGES",
      "SOCKET_ON_READ",
      "SOCKET_DOCUMENT_CHANGED",
      "SOCKET_USER_CHANGED",
      "SOCKET_FOND_CHANGED",
      "SOCKET_INFO_SETTING_CHANGED"
    ]),
    ...mapActions("Messages", ["GET_NOTIFICATIONS"]),
    logout() {
      this.LOGOUT();
      this.SOCKET_DISCONNECT();
      this.$router.push("/login");
    },
    size() {
      return window.innerWidth
    },
    showMessages() {
      if (!this.$route.matched.some((el) => el.path === "/cabinet/messages")) {
        if (this.size() < 961) {
          this.$router.push(this.MESSAGES_ROUTE);
        } else {
          this.$router.push("/cabinet/messages");
        }
      }
    },
    sendMessage() {
      if (
        !this.$route.matched.some(
          (el) => el.path === "/cabinet/contacting-the-mc"
        )
      ) {
        this.$router.push("/cabinet/contacting-the-mc");
      }
    },
  },
  mounted() {
    this.CLEAR_LOGIN();
    if (!this.IS_CONNECTED) this.SOCKET_CONNECT();
    this.SOCKET_visibleSellShares();
    this.SOCKET_visibleChanges();
    this.SOCKET_isVisibleSellShares();
    this.SOCKET_isVisibleChanges();
    this.SOCKET_GET_OUT();
    this.GET_ABUT_INFORMATION();
    this.SOCKET_NEW_MESSAGES();
    this.GET_NOTIFICATIONS();
    this.SOCKET_ON_READ();
    this.SOCKET_DOCUMENT_CHANGED();
    this.SOCKET_USER_CHANGED();
    this.SOCKET_FOND_CHANGED();
    this.SOCKET_INFO_SETTING_CHANGED();
  },
};
</script>
