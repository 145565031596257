<template>
  <div class="login-container__content-footer">
    <p v-html="getFOOTER.text" />
    <!--    <p>© 2021 Все права защищены ЗАО ИГ «РВМ Капитал»</p>-->
    <!--    <p>105064, г. Москва, ул. Земляной Вал, д. 9</p>-->
    <!--    <p>Тел.: +7 495 660-70-30</p>-->
    <!--    <p>Факс: +7 495 660-70-32</p>-->
    <!--    <p>E-mail: info@rwmcapital.ru</p>-->
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  name: "FooterBar",
  computed: {
    ...mapGetters("AboutModule", ["getFOOTER"]),
  },
};
</script>

<style scoped lang="scss">
.login-container__content-footer {
  display: flex;
  flex: 0 1 auto;
  position: relative;
  padding: 44px 42px;
  flex-direction: column;
  background: $RWM-block;
  @media screen and (min-width: 961px) {
    //min-height: 116px;
    //padding-left: 0;
    margin-left: -44px;
  }
  &:before {
    content: "";
    position: absolute;
    left: 0;
    opacity: 0.5;
    right: 0;
    top: 0;
    //border-bottom: 2px solid $RWM-grey-super-light;
  }
  & p {
    text-align: left;

    font-weight: 500;
    font-size: 17px;
    line-height: 23px;
    color: $RWM-dark;
  }
}
</style>
